(function (global, factory) {
  typeof exports === 'object' && typeof module !== 'undefined' ? factory(exports, require('hyperapp')) :
  typeof define === 'function' && define.amd ? define(['exports', 'hyperapp'], factory) :
  (factory((global.hyperappHtml = {}),global.hyperapp));
}(this, (function (exports,hyperapp) { 'use strict';

  function vnode(name) {
    return function (attributes, children) {
      return typeof attributes === "object" && !Array.isArray(attributes)
        ? hyperapp.h(name, attributes, children)
        : hyperapp.h(name, {}, attributes)
    }
  }


  function a(attributes, children) {
    return vnode("a")(attributes, children)
  }

  function abbr(attributes, children) {
    return vnode("abbr")(attributes, children)
  }

  function address(attributes, children) {
    return vnode("address")(attributes, children)
  }

  function area(attributes, children) {
    return vnode("area")(attributes, children)
  }

  function article(attributes, children) {
    return vnode("article")(attributes, children)
  }

  function aside(attributes, children) {
    return vnode("aside")(attributes, children)
  }

  function audio(attributes, children) {
    return vnode("audio")(attributes, children)
  }

  function b(attributes, children) {
    return vnode("b")(attributes, children)
  }

  function bdi(attributes, children) {
    return vnode("bdi")(attributes, children)
  }

  function bdo(attributes, children) {
    return vnode("bdo")(attributes, children)
  }

  function blockquote(attributes, children) {
    return vnode("blockquote")(attributes, children)
  }

  function br(attributes, children) {
    return vnode("br")(attributes, children)
  }

  function button(attributes, children) {
    return vnode("button")(attributes, children)
  }

  function canvas(attributes, children) {
    return vnode("canvas")(attributes, children)
  }

  function caption(attributes, children) {
    return vnode("caption")(attributes, children)
  }

  function cite(attributes, children) {
    return vnode("cite")(attributes, children)
  }

  function code(attributes, children) {
    return vnode("code")(attributes, children)
  }

  function col(attributes, children) {
    return vnode("col")(attributes, children)
  }

  function colgroup(attributes, children) {
    return vnode("colgroup")(attributes, children)
  }

  function data(attributes, children) {
    return vnode("data")(attributes, children)
  }

  function datalist(attributes, children) {
    return vnode("datalist")(attributes, children)
  }

  function dd(attributes, children) {
    return vnode("dd")(attributes, children)
  }

  function del(attributes, children) {
    return vnode("del")(attributes, children)
  }

  function details(attributes, children) {
    return vnode("details")(attributes, children)
  }

  function dfn(attributes, children) {
    return vnode("dfn")(attributes, children)
  }

  function dialog(attributes, children) {
    return vnode("dialog")(attributes, children)
  }

  function div(attributes, children) {
    return vnode("div")(attributes, children)
  }

  function dl(attributes, children) {
    return vnode("dl")(attributes, children)
  }

  function dt(attributes, children) {
    return vnode("dt")(attributes, children)
  }

  function em(attributes, children) {
    return vnode("em")(attributes, children)
  }

  function embed(attributes, children) {
    return vnode("embed")(attributes, children)
  }

  function fieldset(attributes, children) {
    return vnode("fieldset")(attributes, children)
  }

  function figcaption(attributes, children) {
    return vnode("figcaption")(attributes, children)
  }

  function figure(attributes, children) {
    return vnode("figure")(attributes, children)
  }

  function footer(attributes, children) {
    return vnode("footer")(attributes, children)
  }

  function form(attributes, children) {
    return vnode("form")(attributes, children)
  }

  function h1(attributes, children) {
    return vnode("h1")(attributes, children)
  }

  function h2(attributes, children) {
    return vnode("h2")(attributes, children)
  }

  function h3(attributes, children) {
    return vnode("h3")(attributes, children)
  }

  function h4(attributes, children) {
    return vnode("h4")(attributes, children)
  }

  function h5(attributes, children) {
    return vnode("h5")(attributes, children)
  }

  function h6(attributes, children) {
    return vnode("h6")(attributes, children)
  }

  function header(attributes, children) {
    return vnode("header")(attributes, children)
  }

  function hr(attributes, children) {
    return vnode("hr")(attributes, children)
  }

  function i(attributes, children) {
    return vnode("i")(attributes, children)
  }

  function iframe(attributes, children) {
    return vnode("iframe")(attributes, children)
  }

  function img(attributes, children) {
    return vnode("img")(attributes, children)
  }

  function input(attributes, children) {
    return vnode("input")(attributes, children)
  }

  function ins(attributes, children) {
    return vnode("ins")(attributes, children)
  }

  function kbd(attributes, children) {
    return vnode("kbd")(attributes, children)
  }

  function label(attributes, children) {
    return vnode("label")(attributes, children)
  }

  function legend(attributes, children) {
    return vnode("legend")(attributes, children)
  }

  function li(attributes, children) {
    return vnode("li")(attributes, children)
  }

  function main(attributes, children) {
    return vnode("main")(attributes, children)
  }

  function map(attributes, children) {
    return vnode("map")(attributes, children)
  }

  function mark(attributes, children) {
    return vnode("mark")(attributes, children)
  }

  function menu(attributes, children) {
    return vnode("menu")(attributes, children)
  }

  function menuitem(attributes, children) {
    return vnode("menuitem")(attributes, children)
  }

  function meter(attributes, children) {
    return vnode("meter")(attributes, children)
  }

  function nav(attributes, children) {
    return vnode("nav")(attributes, children)
  }

  function object(attributes, children) {
    return vnode("object")(attributes, children)
  }

  function ol(attributes, children) {
    return vnode("ol")(attributes, children)
  }

  function optgroup(attributes, children) {
    return vnode("optgroup")(attributes, children)
  }

  function option(attributes, children) {
    return vnode("option")(attributes, children)
  }

  function output(attributes, children) {
    return vnode("output")(attributes, children)
  }

  function p(attributes, children) {
    return vnode("p")(attributes, children)
  }

  function param(attributes, children) {
    return vnode("param")(attributes, children)
  }

  function pre(attributes, children) {
    return vnode("pre")(attributes, children)
  }

  function progress(attributes, children) {
    return vnode("progress")(attributes, children)
  }

  function q(attributes, children) {
    return vnode("q")(attributes, children)
  }

  function rp(attributes, children) {
    return vnode("rp")(attributes, children)
  }

  function rt(attributes, children) {
    return vnode("rt")(attributes, children)
  }

  function rtc(attributes, children) {
    return vnode("rtc")(attributes, children)
  }

  function ruby(attributes, children) {
    return vnode("ruby")(attributes, children)
  }

  function s(attributes, children) {
    return vnode("s")(attributes, children)
  }

  function samp(attributes, children) {
    return vnode("samp")(attributes, children)
  }

  function section(attributes, children) {
    return vnode("section")(attributes, children)
  }

  function select(attributes, children) {
    return vnode("select")(attributes, children)
  }

  function small(attributes, children) {
    return vnode("small")(attributes, children)
  }

  function source(attributes, children) {
    return vnode("source")(attributes, children)
  }

  function span(attributes, children) {
    return vnode("span")(attributes, children)
  }

  function strong(attributes, children) {
    return vnode("strong")(attributes, children)
  }

  function sub(attributes, children) {
    return vnode("sub")(attributes, children)
  }

  function summary(attributes, children) {
    return vnode("summary")(attributes, children)
  }

  function sup(attributes, children) {
    return vnode("sup")(attributes, children)
  }

  function svg(attributes, children) {
    return vnode("svg")(attributes, children)
  }

  function table(attributes, children) {
    return vnode("table")(attributes, children)
  }

  function tbody(attributes, children) {
    return vnode("tbody")(attributes, children)
  }

  function td(attributes, children) {
    return vnode("td")(attributes, children)
  }

  function textarea(attributes, children) {
    return vnode("textarea")(attributes, children)
  }

  function tfoot(attributes, children) {
    return vnode("tfoot")(attributes, children)
  }

  function th(attributes, children) {
    return vnode("th")(attributes, children)
  }

  function thead(attributes, children) {
    return vnode("thead")(attributes, children)
  }

  function time(attributes, children) {
    return vnode("time")(attributes, children)
  }

  function tr(attributes, children) {
    return vnode("tr")(attributes, children)
  }

  function track(attributes, children) {
    return vnode("track")(attributes, children)
  }

  function u(attributes, children) {
    return vnode("u")(attributes, children)
  }

  function ul(attributes, children) {
    return vnode("ul")(attributes, children)
  }

  function video(attributes, children) {
    return vnode("video")(attributes, children)
  }

  function wbr(attributes, children) {
    return vnode("wbr")(attributes, children)
  }

  exports.a = a;
  exports.abbr = abbr;
  exports.address = address;
  exports.area = area;
  exports.article = article;
  exports.aside = aside;
  exports.audio = audio;
  exports.b = b;
  exports.bdi = bdi;
  exports.bdo = bdo;
  exports.blockquote = blockquote;
  exports.br = br;
  exports.button = button;
  exports.canvas = canvas;
  exports.caption = caption;
  exports.cite = cite;
  exports.code = code;
  exports.col = col;
  exports.colgroup = colgroup;
  exports.data = data;
  exports.datalist = datalist;
  exports.dd = dd;
  exports.del = del;
  exports.details = details;
  exports.dfn = dfn;
  exports.dialog = dialog;
  exports.div = div;
  exports.dl = dl;
  exports.dt = dt;
  exports.em = em;
  exports.embed = embed;
  exports.fieldset = fieldset;
  exports.figcaption = figcaption;
  exports.figure = figure;
  exports.footer = footer;
  exports.form = form;
  exports.h1 = h1;
  exports.h2 = h2;
  exports.h3 = h3;
  exports.h4 = h4;
  exports.h5 = h5;
  exports.h6 = h6;
  exports.header = header;
  exports.hr = hr;
  exports.i = i;
  exports.iframe = iframe;
  exports.img = img;
  exports.input = input;
  exports.ins = ins;
  exports.kbd = kbd;
  exports.label = label;
  exports.legend = legend;
  exports.li = li;
  exports.main = main;
  exports.map = map;
  exports.mark = mark;
  exports.menu = menu;
  exports.menuitem = menuitem;
  exports.meter = meter;
  exports.nav = nav;
  exports.object = object;
  exports.ol = ol;
  exports.optgroup = optgroup;
  exports.option = option;
  exports.output = output;
  exports.p = p;
  exports.param = param;
  exports.pre = pre;
  exports.progress = progress;
  exports.q = q;
  exports.rp = rp;
  exports.rt = rt;
  exports.rtc = rtc;
  exports.ruby = ruby;
  exports.s = s;
  exports.samp = samp;
  exports.section = section;
  exports.select = select;
  exports.small = small;
  exports.source = source;
  exports.span = span;
  exports.strong = strong;
  exports.sub = sub;
  exports.summary = summary;
  exports.sup = sup;
  exports.svg = svg;
  exports.table = table;
  exports.tbody = tbody;
  exports.td = td;
  exports.textarea = textarea;
  exports.tfoot = tfoot;
  exports.th = th;
  exports.thead = thead;
  exports.time = time;
  exports.tr = tr;
  exports.track = track;
  exports.u = u;
  exports.ul = ul;
  exports.video = video;
  exports.wbr = wbr;

  Object.defineProperty(exports, '__esModule', { value: true });

})));
